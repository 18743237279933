import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return !_vm.routerInfo.hidden ? _c(_vm.menuComponent, {
    tag: "component",
    attrs: {
      routerInfo: _vm.routerInfo
    }
  }, [_vm.routerInfo.children && _vm.routerInfo.children.length ? _vm._l(_vm.routerInfo.children, function (item) {
    return _c("AsideComponent", {
      key: item.name,
      attrs: {
        routerInfo: item
      }
    });
  }) : _vm._e()], 2) : _vm._e();
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };